import logo from './logo.png';
import './App.css';
import React from 'react'

function App() {
  const pricingRef = React.useRef(null)
  const scrollToPricing = React.useCallback((e) => {
    e.preventDefault();
    pricingRef.current.scrollIntoView({ behavior: 'smooth' });
  },[pricingRef]);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <h1>
          Londam        
        </h1>
        <h2>
          Recording studio based in amsterdam
        </h2>
        <form onSubmit={scrollToPricing}>
          <input type="submit" className='submit-button submit-button-primary' value="Studio rental" />
        </form>
      </header>
      <div className='row row-reversed-mobile'>
        <div className='row-left'>
          <div className='text-box'>
            <div>
            <h2>a magical workspace</h2>
            <p>
              Located in Amsterdam, our versatile studio is designed to inspire creativity and meet a range of needs. Perfect for music producers, audio engineers, or anyone looking for a professional space to host workshops, presentations, or events.
            </p>
            <p>
              With a high-end audio system built for mixing and mastering, you’ll enjoy exceptional sound quality in a thoughtfully crafted environment that promotes both productivity and creativity. Whether you're working on technical projects or brainstorming new ideas, this space has everything you need.            
            </p>
            </div>
          </div>
        </div>
        <div className='row-right'>
        <div class="center-cropped">
        <video controls autoplay poster="/images/video.png" >
        <source src={`${process.env.PUBLIC_URL}/images/videotour.mp4`} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
        </div>
        </div>
      </div>
      <div className='row'>
        <div className='row-left'>
          <div class="center-cropped" style={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/images/spaceship.jpg')`
          }} />
        </div>
        <div className='row-right'>
          <div className='text-box'>
            <div>
              <h2>The spaceship</h2>
              <p>
                  Step into our studio and be transported to a world beyond Earth, the entrance is transformed into a futuristic spaceship. Octagon-shaped windows offer a stunning view of deep space, while comfortable seating invites you to buckle up, just in case space debris crosses your path.
                </p>
                <p>
                  The ship is equipped with a private kitchen, toilet, and a cozy office area, providing everything you need to work comfortably, whether alone or with a team.
                </p>
                <p>
                  Designed to disconnect you from the distractions of planet Earth, this immersive environment encourages to lose yourself in the creative process, without having any rules, space is the place!
              </p>
              </div>
            </div>
        </div>
      </div>
      <div className='row row-reversed-mobile'>
        <div className='row-left'>
          <div className='text-box'>
            <div>
            <h2>The studio</h2>
            <p>
              Equipped with high-end technology, this 35m2 studio provides the perfect environment for mixing, mastering, or music production. The studio's ATC SCM100 speakers, paired with a Helios custom 15” direct servo subwoofer and the Trinnov Nova active room correction system, ensure exceptional audio clarity and precision.
            </p>
            <p>
              The studio is designed for in-the-box production, and all you need is a laptop to connect to the gear. There is a 4k beamer with low latency (16 milliseconds) that can be used as external screen. in the back of the studio is a big couch in case you are bringing any friends over.
            </p>
            </div>
          </div>
        </div>
        <div className='row-right'>
        <div class="center-cropped" style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/images/studio.jpg')`
          }} />        
        </div>
      </div>
      <div className='row'>
        <div className='row-left'>
        <div class="center-cropped" style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/images/gear.jpg')`
          }} />        
        </div>
        <div className='row-right'>
        <div className='text-box'>
            <div>
            <h2>Gearlist</h2>
            <ul>
              <li>
                2x ATC SCM100
              </li>
              <li>  
                Helios Custom 15” Direct Servo Subwoofer
              </li>
              <li>
                Trinnov Nova Bundle 4
              </li>
              <li>
                Orion Studio Rev. 2017
              </li>
              <li>
                M8U eX usb midi interface
              </li>
              <li>
                Yamaha Montage 7
              </li>
              <li>
                APC 40 MKII
              </li>
              <li>
                Nord Rack 2x
              </li>
              <li>
                Pro-ject X1 Turntable with Pick It S2 MM
              </li>
              <li>
                Pro-ject Phono Box S3
              </li>
              <li>
                Sennheiser HD-650 & HD-25 Headphones
              </li>
              <li>
                DDJ 1000 Controller
              </li>
              <li>
                BenQ Projector
              </li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='pricing' ref={pricingRef}>
        <div>
          <h2>
            Studio rental
          </h2>
        </div>
        <div className='pricing-blocks'>
          <div className='pricing-block'>
          <p>
            <h4>Studio Session (3 hours)</h4>
            <p className='price'>€290.40 (incl. VAT)</p>
            €96.80 per hour
            <form action="https://calendly.com/londam/studio-session">
                <input type="submit" value="Book your session" className='submit-button submit-button-secondary' />
              </form>
          </p>
          </div>
          <div className='pricing-block'>
            <h4 class='primary-color'>WELKOM DEAL (3 hours)</h4>
            <h4>Studio Session before Nov 30th</h4>

            <p className='price'>€75 (incl. VAT)</p>
            €25 per hour
            <form action='https://calendly.com/londam/studio-session-3-hours-clone' >
                <input type="submit" value="Book your session" className='submit-button submit-button-primary'/>
              </form>

          </div>
          <div className='pricing-block'>
          <p>
          <h4>Studio Session (6 hours)</h4>
          <p className='price'>€406.56 (incl. VAT)</p>
            €67.76 per hour
            <form action='https://calendly.com/londam/6-hour-session'>
                <input type="submit" value="Book your session" className='submit-button submit-button-secondary'/>
              </form>
          </p>
          </div>
        </div>
        <p class='text-fullwidth'>
        All you need to bring is your laptop! Upon arrival, a person to support will be there to assist with setup, ensuring a smooth and seamless start to your session. 
        <br />
        For any custom requests or special bookings feel free to contact us at info@londam.studio and we’ll be happy to provide a tailored offer.       
        </p>
      </div>
      <div className='demo-block'>
          <p>
            <h4>Need a little more convincing?</h4>
            <p>Book a free 15-minute demo, and let us show you the space. </p>
            <form action="https://calendly.com/londam/30min">
                <input type="submit" value="Book your demo" className='submit-button submit-button-secondary' />
              </form>
          </p>
          </div>
      <footer>
        Ottho Heldringstraat 25H<br />
        1066XT Amsterdam <br />
        The Netherlands <br />
        +31(0)642576119 <br />
        info@londam.studio <br />
    </footer>
    </div>
    
  );
}

export default App;
